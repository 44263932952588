import { inject, ref } from 'vue'
import getEnv from '@/utils/env.js'
import { createFetch } from '@vueuse/core'
import { oidc } from '@/utils/oidc.js'
import mime from 'mime-types'

export const useCustomFetch = createFetch({
  baseUrl: getEnv('VITE_EPCAML_API_URL') + '/portal',
  options: {
    async beforeFetch({ url, options, cancel }) {
      const user = await oidc.getUser()

      if (!user || !user.access_token) {
        cancel()
      }

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${user.access_token}`
      }

      return options
    }
  }
})

export function useAPIFetch(uri, options = {}) {
  const oidc = inject('oidc')
  const data = ref(undefined)
  const responseType = ref(undefined)
  const responseExtension = ref(undefined)
  const error = ref(undefined)
  const isFetching = ref(false)

  async function execute(requestOptions = {}) {
    if (requestOptions.silent === undefined || requestOptions.silent === false) {
      isFetching.value = true
    }

    // Copy needed to Prevent Original URI from manipulation
    let uriCopy = String(uri)

    if (typeof requestOptions.urlParams === 'object') {
      for (const [key, value] of Object.entries(requestOptions.urlParams)) {
        uriCopy = uriCopy.replace(':' + key, value)
      }
    }

    let url = new URL('/portal/' + uriCopy, getEnv('VITE_EPCAML_API_URL'))
    const user = await oidc.getUser()

    if (typeof requestOptions.queryParams === 'object') {
      for (const [key, value] of Object.entries(requestOptions.queryParams)) {
        if (Array.isArray(value)) {
          value.forEach((entry) => {
            url.searchParams.append(key, entry)
          })
        } else {
          url.searchParams.append(key, value)
        }
      }
    }

    try {
      let fetchOptions = {
        headers: { Authorization: 'Bearer ' + user.access_token, ...requestOptions.headers }
      }

      if (requestOptions.method !== undefined) {
        fetchOptions.method = requestOptions.method
      }

      if (requestOptions.body !== undefined) {
        fetchOptions.body = requestOptions.body
      }

      if (typeof requestOptions.jsonBody === 'object') {
        fetchOptions.body = JSON.stringify(requestOptions.jsonBody)
        fetchOptions.headers = { ...fetchOptions.headers, 'Content-Type': 'application/json' }
      }

      const response = await fetch(url, fetchOptions)

      if (
        response.status === 404 &&
        options !== undefined &&
        typeof options['404'] === 'function'
      ) {
        options['404'](response)
      }

      if (!response.ok) {
        if (response.status === 401) {
          // window.location.reload()
        }

        throw new Error(response.status + ' ' + response.statusText)
      }

      responseType.value = response.headers.get('Content-Type')
      let responseData

      if (responseType.value.startsWith('application/json')) {
        responseData = await response.json()
      } else if (responseType.value.startsWith('application/xml')) {
        responseData = await response.text()
      } else if (['text/plain'].includes(responseType.value)) {
        responseData = await response.text()
      } else {
        responseData = await response.blob()
        responseExtension.value = mime.extension(responseType.value)
      }

      if (options !== undefined && typeof options['afterFetch'] === 'function') {
        responseData = options['afterFetch'](responseData)
      }

      data.value = responseData
    } catch (e) {
      error.value = e
      console.log(e)
    }

    if (requestOptions.fetchingIndicatorDelay !== undefined) {
      setTimeout(() => (isFetching.value = false), requestOptions.fetchingIndicatorDelay)
    } else {
      isFetching.value = false
    }
  }

  if (options.immediate) {
    execute(options?.requestOptions)
  }

  return { data, error, isFetching, execute, responseType, responseExtension }
}
