<script setup>
import { useLocalStorage, useStorage } from '@vueuse/core'
import { inject, ref, watch } from 'vue'
import { Server, ServerOff, Moon, Sun, Webhook, WebhookOff } from 'lucide-vue-next'

const localMocking = useStorage('mocking', false)
const isDark = inject('isDark')
const languageDropDownOpen = ref(false)
const intercept = useLocalStorage('intercept', false)

watch(localMocking, () => window.location.reload())
</script>

<template>
  <teleport to="body">
    <div
      class="z-20 bg-slate-500 fixed right-0 top-1/2 -translate-y-1/2 p-1 rounded-l shadow-lg flex flex-col gap-1"
    >
      <button
        type="button"
        @click="localMocking = !localMocking"
        class="p-2 text-white hover:bg-slate-400 rounded-sm"
      >
        <Server v-if="localMocking" :size="20" />
        <ServerOff v-else :size="20" />
      </button>
      <button
        type="button"
        @click="intercept = !intercept"
        class="p-2 text-white hover:bg-slate-400 rounded-sm"
      >
        <Webhook v-if="intercept" :size="20" />
        <WebhookOff v-else :size="20" />
      </button>
      <button
        type="button"
        @click="isDark = !isDark"
        class="p-2 text-white hover:bg-slate-400 rounded-sm"
      >
        <Moon v-if="isDark" :size="20" />
        <Sun v-else :size="20" />
      </button>
      <div class="relative">
        <span
          class="block cursor-pointer text-white p-2 hover:bg-slate-400 rounded-sm"
          :class="{ 'bg-slate-400': languageDropDownOpen }"
          @click="languageDropDownOpen = !languageDropDownOpen"
          >{{ $i18n.locale }}</span
        >
        <div
          v-if="languageDropDownOpen"
          class="absolute shadow right-0.5 bg-slate-400 p-1 flex flex-col gap-1 rounded"
        >
          <div
            v-for="availableLocale in $i18n.availableLocales"
            :key="`locale-${availableLocale}`"
            @click="
              () => {
                $i18n.locale = availableLocale
                languageDropDownOpen = false
              }
            "
            class="cursor-pointer text-sm text-white p-1 hover:bg-slate-300 rounded-sm"
          >
            {{ availableLocale }}
          </div>
        </div>
      </div>

      <!--<select class="w-full" v-model="$i18n.locale">
        <option
          v-for="availableLocale in $i18n.availableLocales"
          :key="`locale-${availableLocale}`"
          :value="availableLocale"
        >
          {{ availableLocale }}
        </option>
      </select>-->
    </div>
  </teleport>
</template>
