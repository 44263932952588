<script setup>
import { ChevronUp } from 'lucide-vue-next'
import { useScroll } from '@vueuse/core'

const { arrivedState } = useScroll(window)

function scrollToTop() {
  window.scrollTo(0, 0)
}
</script>

<template>
  <button
    type="button"
    class="md:hidden fixed right-5 bottom-5 z-50 bg-white dark:bg-brand-dark shadow-lg dark:shadow-black/50 rounded-full p-1.5 border border-brand dark:border-brand-light text-brand dark:text-brand-light"
    :class="{ hidden: arrivedState.top }"
    @click="scrollToTop"
  >
    <ChevronUp :size="24" :stroke-width="1" />
  </button>
</template>
